import DataProvider from './DataProvider'
import RestDataProvider from './RestDataProvider'

export default {
    name: 'AuthProvider',
    install: function (Vue, options) {
        this._options = options;
        var $auth = Vue.prototype.$auth = this;
        this.$router = options.router;
        this.$store = options.store;
        this.$dataProvider = options.backoffice ? RestDataProvider : DataProvider;

        if (options.backoffice) {
            this.token = document.location.search && /authtoken=([^&]*)/i.test(document.location.search) && /authtoken=([^&]*)/i.exec(document.location.search).pop() || '&authentication=anonymous';
            this.$dataProvider.getUserInfo().then(u => {
                $auth.user = u.txt_name;
                $auth.userInfo = u;
            })
            return;
        }
        if (window.sessionStorage) {
            var sessionUser = window.sessionStorage.getItem('user');
            var sessionToken = window.sessionStorage.getItem('token');
            if (sessionUser && sessionToken) {
                this.user = sessionUser;
                this.$store.commit('SET_USER', sessionUser)
                this.token = sessionToken;
                this.$store.commit('SET_TOKEN', sessionToken)
                this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') || '{}');
            }
        }
        if (window.localStorage) {
            var localUser = window.localStorage.getItem('user');
            var localToken = window.localStorage.getItem('token');
            var localUserInfo = JSON.parse(window.localStorage.getItem('appUser'));

            if (localUser && localToken && localUserInfo) {
                this.remember = true
                this.user = localUser;
                this.$store.commit('SET_USER', localUser)
                this.token = localToken;
                this.$store.commit('SET_TOKEN', localToken)
                this.userInfo = localUserInfo;
                this.$store.commit("SET_USERINFO", this.userInfo );
            }
        }
        if (this.confirmToken) {
            this.token = this.confirmToken;
            document.cookie = '.ConfirmAccountSuccess=;expires=Thu, 01 Jan 1970 00:00:01 GMT';

            this.updateToken(
                (/*ok*/) => {
                    if ($auth.$router.currentRoute.path == '/login')
                        $auth.$router.push($auth.redirectTo || "/");
                },
                (/*error*/) => {
                })
        }
        if (!this.user && this.igToken) {
            this.instagramLogin((/*err*/) => {
                document.cookie = '.IGAUTHTOKEN=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
                document.location.search = '';
            })
        }
        if (!this.user && this.fbToken) {
            this.facebookLogin((/*err*/) => {
                document.cookie = '.FBAUTHTOKEN=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
                document.location.search = '';
            })
        }
        if (!this.user && this.twToken) {
            this.twitterLogin((/*err*/) => {
                document.cookie = '.TWAUTHTOKEN=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
                document.location.search = '';
            })
        }
    },
    user: null,
    token: null,
    fbToken: document.cookie.indexOf('.FBAUTHTOKEN=') > -1 ?
        document.cookie.match(/;?\.FBAUTHTOKEN=([^;]*)/).pop() : null,
    igToken: document.cookie.indexOf('.IGAUTHTOKEN=') > -1 ?
        document.cookie.match(/;?\.IGAUTHTOKEN=([^;]*)/).pop() : null,
    twToken: document.cookie.indexOf('.TWAUTHTOKEN=') > -1 ?
        document.cookie.match(/;?\.TWAUTHTOKEN=([^;]*)/).pop() : null,
    confirmToken: document.cookie.indexOf('.ConfirmAccountSuccess=') > -1 ?
        document.cookie.match(/;?\.ConfirmAccountSuccess=([^;]*)/).pop() : null,
    userInfo: null,
    remember: false,
    key : ":B)]sSwJ?5/03q6:|.6;",
    deleteAllCookies() {
        /* Erase all except cookie consent */
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            if (cookie.indexOf("consent") > -1) {
                continue;
            }
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    },
    logout() {
        //call checkouturl/logout
        //console.log("config=> ", this);
        //fetch('//' + this.checkoutUrl + "/logout", {
        //    method: "GET",
        //    mode: 'no-cors',
        //    headers: {
        //        'Access-Control-Allow-Origin': '*'
        //    },
        //}).then();
        //alert("config=> ", this.$parent.$layout.config.appName);

        this.user = null;
        this.token = null;
        this.userInfo = null;
        this.$store.commit('SET_USER', null)
        this.$store.commit('SET_TOKEN', null)
        this.$store.commit('SET_USERINFO', null)
        //this.$store.commit('CLEAR_APPDATA')
        this.$store.commit('CLEAR_DATACACHE')

        localStorage.removeItem("user")
        localStorage.removeItem("userInfo")
        localStorage.removeItem("appUser")
        localStorage.removeItem("token")
        localStorage.removeItem("twinLoginUrl")
        sessionStorage.removeItem("userInfo")
        sessionStorage.removeItem("user")
        sessionStorage.removeItem("token")

        this.deleteAllCookies();
        window.location.reload();
        //this.$router.push(this.redirectTo || "/").catch(() => { window.location.reload(); });
    },
    updateData(name, extended, successCallback, errorCallback) {

        var $auth = this;
        var loginData = new URLSearchParams();
        loginData.append("t", $auth.token);
        loginData.append("g", "t");
        if (name) loginData.append("n", name);
        if (extended) loginData.append("e", btoa(JSON.stringify(extended)));

        /* Making API call to authenticate a user */
        fetch(DataProvider.baseUrl + "/data/login", {
            method: "POST",
            body: loginData
        })
            .then(response => {
                response.json().then(response => {
                    //debugger;

                    /* Checking if error object was returned from the server */
                    if (response.User) {
                        $auth.user = response.User.Name
                        $auth.$store.commit("SET_USER", $auth.user);
                        $auth.token = response.User.Token
                        $auth.$store.commit("SET_TOKEN", $auth.token);
                        $auth.userInfo = response.User
                        $auth.$store.commit("SET_USERINFO", $auth.userInfo);
                        if (this.remember && window.localStorage) {
                            window.localStorage.setItem("user", response.User.Name);
                            window.localStorage.setItem("token", response.User.ScriptorToken);
                        } else if (window.sessionStorage) {
                            window.sessionStorage.setItem("user", response.User.Name);
                            window.sessionStorage.setItem("token", response.User.ScriptorToken);
                        }
                        successCallback(response.User);
                    } else {
                        errorCallback(response.Error);
                    }
                });
            })
            .catch(error => {
                this.$store.commit("TOGGLE_LOADING");
                errorCallback("Server appears to be offline: " + error.toString());
            });
    },
    changePassword(password, successCallback, errorCallback, token) {

        var $auth = this;
        var loginData = new URLSearchParams();
        loginData.append("t", token || $auth.token);
        loginData.append("g", "t");
        loginData.append("p", password);

        /* Making API call to authenticate a user */
        fetch(DataProvider.baseUrl + "/data/login", {
            method: "POST",
            body: loginData
        })
            .then(response => {
                response.json().then(response => {

                    /* Checking if error object was returned from the server */
                    if (response.User) {

                        /*RE: 23/07/2024 - Do not store login data after change password */

                        /*$auth.user = response.User.Name
                        $auth.$store.commit("SET_USER", $auth.user);
                        $auth.token = response.User.Token
                        $auth.$store.commit("SET_TOKEN", $auth.token);
                        $auth.userInfo = response.User
                        $auth.$store.commit("SET_USERINFO", $auth.userInfo);
                        if (this.remember && window.localStorage) {
                            window.localStorage.setItem("user", response.User.Name);
                            window.localStorage.setItem("token", response.User.ScriptorToken);
                        } else if (window.sessionStorage) {
                            window.sessionStorage.setItem("user", response.User.Name);
                            window.sessionStorage.setItem("token", response.User.ScriptorToken);
                        }*/
                        successCallback(response.User);
                    } else {
                        errorCallback(response.Error);
                    }
                });
            })
            .catch(error => {
                this.$store.commit("TOGGLE_LOADING");
                errorCallback("Server appears to be offline: " + error.toString());
            });
    },
    updateToken(successCallback, errorCallback) {
        var $auth = this;
        var loginData = new URLSearchParams();
        if (/authToken=[0-9A-F]*/i.test(document.location.search)) {
            loginData.append("t", document.location.search.match(/authtoken=([0-9A-F]{32})/i)[1]);
            loginData.append("g", "t");
        }
        else {
            loginData.append("t", $auth.userInfo ? $auth.userInfo.ScriptorToken : $auth.token);
            loginData.append("g", "t");
        }
        /* Making API call to authenticate a user */
        fetch(DataProvider.baseUrl + "/data/login", {
            method: "POST",
            body: loginData
        })
        .then(response => {
            response.json().then(response => {
   
                /* Checking if error object was returned from the server */
                if (response.User) {
                    $auth.user = response.User.Name
                    $auth.$store.commit("SET_USER", $auth.user);
                    $auth.token = response.User.ScriptorToken
                    $auth.$store.commit("SET_TOKEN", $auth.token);
                    $auth.userInfo = response.User
                    $auth.$store.commit("SET_USERINFO", $auth.userInfo);
                    if (this.remember && window.localStorage) {
                        window.localStorage.setItem("user", response.User.Name);
                        window.localStorage.setItem("token", response.User.ScriptorToken);
                    } else if (window.sessionStorage) {
                        window.sessionStorage.setItem("user", response.User.Name);
                        window.sessionStorage.setItem("token", response.User.ScriptorToken);
                    }
                    successCallback(response.User);
                } else {

                    if (window.localStorage.appUser) {
                        loginData = new URLSearchParams();
                        loginData.append("u", this.tpyrced(JSON.parse(window.localStorage.appUser).eu));
                        loginData.append("p", this.tpyrced(JSON.parse(window.localStorage.appUser).ep));
                        loginData.append("g", "p");

                        /* Making API call to authenticate a user */
                        fetch(DataProvider.baseUrl + "/data/login", {
                            method: "POST",
                            body: loginData
                        })
                        .then(response => {
                            response.json().then(response => {

                                /* Checking if error object was returned from the server */
                                if (response.User) {
                                    $auth.user = response.User.Name
                                    $auth.$store.commit("SET_USER", $auth.user);
                                    $auth.token = response.User.ScriptorToken
                                    $auth.$store.commit("SET_TOKEN", $auth.token);
                                    $auth.userInfo = response.User
                                    $auth.$store.commit("SET_USERINFO", $auth.userInfo);
                                    if (this.remember && window.localStorage) {
                                        window.localStorage.setItem("user", response.User.Name);
                                        window.localStorage.setItem("token", response.User.ScriptorToken);
                                    } else if (window.sessionStorage) {
                                        window.sessionStorage.setItem("user", response.User.Name);
                                        window.sessionStorage.setItem("token", response.User.ScriptorToken);
                                    }
                                    successCallback(response.User);
                                } else {
                                        $auth.user = null;
                                        $auth.token = null;
                                        $auth.userInfo = null;
                                        window.localStorage.clear();
                                        window.sessionStorage.clear();
                                        errorCallback(response.Error);
                                }
                            });
                        })
                        .catch(error => {
                            this.$store.commit("TOGGLE_LOADING");
                            errorCallback("Server appears to be offline: " + error.toString());
                        });

                    }
                    else {
                        $auth.user = null;
                        $auth.token = null;
                        $auth.userInfo = null;
                        window.localStorage.clear();
                        window.sessionStorage.clear();
                        errorCallback(response.Error);
                    }
                }
            });
        })
        .catch(error => {
            this.$store.commit("TOGGLE_LOADING");
            errorCallback("Server appears to be offline: " + error.toString());
        });
    },
    login(username, password, remember, errorCallback, IsToken, successCallback) {
        var $auth = this;
        if (this._options.k4t) {
            var loginData = new URLSearchParams();
            loginData.append("UserName", username);
            loginData.append("Password", password);
            loginData.append("IsAjax", true);
            
            if (IsToken) {
                loginData.append("IsToken", IsToken);
            }
            /* Making API call to authenticate a user */
            fetch(DataProvider.baseUrl + "/k4t/login", {
                method: "POST",
                body: loginData
            })
                .then(response => {
                    response.json().then(response => {
                        /* Checking if error object was returned from the server */
                        if (!response.result) {
                            errorCallback('Error: ' + response.message);
                        } else if (response.user) {
                            $auth.user = response.user.FullName
                            $auth.$store.commit("SET_USER", $auth.user);
                            $auth.token = response.token;
                            $auth.$store.commit("SET_TOKEN", $auth.token);
                            $auth.userInfo = response.user;
                            if (IsToken)
                                $auth.userInfo.IsToken = IsToken;
                            $auth.$store.commit("SET_USERINFO", $auth.userInfo);
                            if (remember && window.localStorage) {
                                window.localStorage.setItem("appUser", JSON.stringify({ ...response.user }));
                                window.localStorage.setItem("user", response.user.FullName);
                                window.localStorage.setItem("token", response.token);
                            }
                            if (window.sessionStorage) {
                                window.sessionStorage.setItem("user", response.user.FullName);
                                window.sessionStorage.setItem("token", response.token);
                            }
                            if (this.redirectTo) {
                                successCallback({ "u": username, "p": password });
                                this.$router.push(this.redirectTo);
                            }
                            else {
                                successCallback({ "u": username, "p": password });
                                window.location.reload();
                            }
                        }
                    }).catch(error => {
                        this.$store.commit("TOGGLE_LOADING");
                        errorCallback("Server error: " + error.toString());
                    });
                })
                .catch(error => {
                    this.$store.commit("TOGGLE_LOADING");
                    errorCallback("Server appears to be offline: " + error.toString());
                });
        }
        else {
            loginData = new URLSearchParams();
            loginData.append("u", username);
            loginData.append("p", password);
            loginData.append("g", "p");

            let ep = this.tpyrcne(password);
            let eu = this.tpyrcne(username);
            /* Making API call to authenticate a user */
            fetch(DataProvider.baseUrl + "/data/login", {
                method: "POST",
                body: loginData
            })
                .then(response => {
                    response.json().then(response => {
                        //debugger;

                        /* Checking if error object was returned from the server */
                        if (response.Error) {
                            errorCallback(response.Error + ': ' + response.Message);
                        } else if (response.User) {
                            $auth.user = response.User.Name
                            $auth.$store.commit("SET_USER", $auth.user);
                            $auth.token = response.User.ScriptorToken
                            $auth.$store.commit("SET_TOKEN", $auth.token);
                            $auth.userInfo = response.User
                            $auth.$store.commit("SET_USERINFO", $auth.userInfo);
                            if (remember && window.localStorage) {
                                window.localStorage.setItem("appUser", JSON.stringify({ ...response.User.Parts, eu, ep }));
                            }
                            if (window.sessionStorage) {
                                window.sessionStorage.setItem("user", response.User.Name);
                                window.sessionStorage.setItem("token", response.User.ScriptorToken);
                            }

                            successCallback({ "u": username, "p": password });
                            $auth.$router.push($auth.redirectTo || "/");
                        }
                    });
                })
                .catch(error => {
                    this.$store.commit("TOGGLE_LOADING");
                    errorCallback("Server appears to be offline: " + error.toString());
                });
        }
    },
    tpyrcne(text) {
        
        return [...text].map((x, i) =>
            (x.codePointAt() ^ this.key.charCodeAt(i % this.key.length) % 255)
                .toString(16)
                .padStart(2, "0")
        ).join('');
    },
    tpyrced(text) {
        return String.fromCharCode(...text.match(/.{1,2}/g)
            .map((e, i) =>
                parseInt(e, 16) ^ this.key.charCodeAt(i % this.key.length) % 255)
        )
    },
    recoverPassword(username, errorCallback, redirect) {
        var $auth = this;
        var loginData = new URLSearchParams();
        loginData.append("u", username);
        if (redirect) {
            loginData.append("r", redirect);
        }


        /* Making API call to authenticate a user */
        fetch(DataProvider.baseUrl + "/data/recoverpassword ", {
            method: "POST",
            body: loginData
        })
            .then(response => {
                response.json().then(response => {
                    //debugger;

                    /* Checking if error object was returned from the server */
                    if (response.Error) {
                        errorCallback(response.Error + ': ' + response.Message);
                    } else if (response.User) {
                        $auth.user = response.User.Name
                        $auth.$store.commit("SET_USER", $auth.user);
                        $auth.token = response.User.ScriptorToken
                        $auth.$store.commit("SET_TOKEN", $auth.token);
                        $auth.userInfo = response.User
                        $auth.$store.commit("SET_USERINFO", $auth.userInfo);
                        if (window.localStorage) {
                            window.localStorage.setItem("user", response.User.Name);
                            window.localStorage.setItem("token", response.User.ScriptorToken);
                        } else if (window.sessionStorage) {
                            window.sessionStorage.setItem("user", response.User.Name);
                            window.sessionStorage.setItem("token", response.User.ScriptorToken);
                        }

                        $auth.$router.push($auth.redirectTo || "/");
                    }
                });
            })
            .catch(error => {
                this.$store.commit("TOGGLE_LOADING");
                errorCallback("Server appears to be offline: " + error.toString());
            });
    },
    register(fullName, username, password, extend, successCallback, errorCallback) {

        var loginData = new URLSearchParams();
        loginData.append("n", fullName);
        loginData.append("u", username);
        loginData.append("p", password);
        loginData.append("e", JSON.stringify(extend));

        /* Making API call to register a user */
        fetch(DataProvider.baseUrl + "/data/register", {
            method: "POST",
            body: loginData
        })
            .then(response => {
                response.json().then(response => {
                    //debugger;

                    /* Checking if error object was returned from the server */
                    if (response.Error) {
                        errorCallback(response.Error + ': ' + response.Message);
                    } else if (response.User) {
                        successCallback(response.User);
                    }
                });
            })
            .catch(error => {
                errorCallback("Server appears to be offline: " + error.toString());
            });
    },
    twitterLogin(errorCallback) {
        this.socialLogin("tw", errorCallback)
    },
    facebookLogin(errorCallback) {
        this.socialLogin("fb", errorCallback)
    },
    instagramLogin(errorCallback, requestEmailCallback) {
        this.socialLogin("ig", errorCallback, requestEmailCallback)
    },
    instagramConfirmEmail(email, errorCallback, requestEmailCallback) {
        this.loginConfirmEmailAddress = email;
        this.socialLogin("ig", errorCallback, requestEmailCallback)
    },
    socialLogin(g, errorCallback, requestEmailCallback) {
        var $auth = this;
        var loginData = new URLSearchParams();
        loginData.append("g", g);
        if (this.loginConfirmEmailAddress)
            loginData.append("u", this.loginConfirmEmailAddress);

        /* Making API call to authenticate a user */
        fetch(DataProvider.baseUrl + "/data/login", {
            method: "POST",
            credentials: "include",
            body: loginData
        })
            .then(response => {
                if (response.status != 200) {
                    this.$store.commit("TOGGLE_LOADING");
                    errorCallback("Server request error: " + response.statusText);
                }
                response.json().then(response => {
                    //debugger;

                    /* Checking if error object was returned from the server */
                    if (response.Error) {
                        errorCallback(response.Error + ': ' + response.Message);
                    } else if (response.Message == "RequestEmailConfirmation") {
                        (requestEmailCallback || $auth.requestEmailCallback || (() => {
                            throw 'requestEmailCallback is not defined';
                        }))(response.User);
                    } else if (response.User && response.User.Inactive != true) {
                        $auth.user = response.User.Name
                        $auth.$store.commit("SET_USER", $auth.user);
                        $auth.token = response.User.Token
                        $auth.$store.commit("SET_TOKEN", $auth.token);
                        $auth.userInfo = response.User
                        $auth.$store.commit("SET_USERINFO", $auth.userInfo);
                        if (window.localStorage) {
                            window.localStorage.setItem("user", response.User.Name);
                            window.localStorage.setItem("token", response.User.Token);
                        } else if (window.sessionStorage) {
                            window.sessionStorage.setItem("user", response.User.Name);
                            window.sessionStorage.setItem("token", response.User.Token);
                        }

                        document.cookie = '.FBAUTHTOKEN=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
                        document.cookie = '.TWAUTHTOKEN=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
                        document.cookie = '.IGAUTHTOKEN=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
                        document.cookie = '.ConfirmAccountSuccess=;expires=Thu, 01 Jan 1970 00:00:01 GMT';

                        $auth.$router.push($auth.redirectTo || "/");
                    }
                    else if (response.URL) {
                        window.location = response.URL;//.replace(/redirect_uri=.*%2fdata%2flogin/, (a, b) => a + encodeURIComponent('?g=' + g + '&r=' + encodeURIComponent(location.href)));
                        return;
                    }
                    else if (requestEmailCallback && response.Message == 'EmailConfirmationSent') {
                        requestEmailCallback(response.User);
                    }
                });
            })
            .catch(error => {
                this.$store.commit("TOGGLE_LOADING");
                errorCallback("Server appears to be offline: " + error.toString());
            });
    },
    redirectTo: null,
    removeModalIfExists() {
        if ($('.modal-backdrop') != null) {
            $('.modal-backdrop').hide();
            document.body.classList.remove('modal-open');
            document.body.style.paddingRight = "0px";
        }
    },
    requireLogin(successCallback, errorCallBack) {
        
        if (window.sessionStorage) {
            var sessionUser = window.sessionStorage.getItem('user');
            var sessionToken = window.sessionStorage.getItem('ScriptorToken');
            if (!sessionToken) {
                sessionToken = window.sessionStorage.getItem('token');
            }
            if (sessionUser && sessionToken) {
                this.$store.commit('SET_USER', sessionUser)
                this.$store.commit('SET_TOKEN', sessionToken)
            }
        }
        else if (window.localStorage) {
            var localUser = window.localStorage.getItem('user');
            var localToken = window.sessionStorage.getItem('ScriptorToken');
            if (!sessionToken) {
                sessionToken = window.sessionStorage.getItem('token');
            }
            if (localUser && localToken) {
                this.remember = true
                this.$store.commit('SET_USER', localUser)
                this.$store.commit('SET_TOKEN', localToken)
            }
        }

        if ((!this.userInfo || this.userInfo.ScriptorToken == null) && !window.localStorage.appUser) {
            if (errorCallBack) {
                errorCallBack();
            }
            else if (this.onLoginError) {
                this.onLoginError();
            }
            else if (this.$router.history.current.path != '/login') {
                this.removeModalIfExists();
                this.redirectTo = this.$router.mode == 'hash' ?
                    document.location.hash :
                    (document.location.pathname + document.location.search);
                this.$router.push('/login');
            }
            return false;
        }
        if (this._options.k4t) {
            if (successCallback)
                successCallback(this.userInfo)
        }
        else if (this.userInfo.ScriptorToken != null) {

            var loginData = new URLSearchParams();

            if (window.localStorage.appUser) {
                loginData.append("u", this.tpyrced(JSON.parse(window.localStorage.appUser).eu));
                loginData.append("p", this.tpyrced(JSON.parse(window.localStorage.appUser).ep));
                loginData.append("g", "p");
            }
            else {
                loginData.append('t', this.userInfo.ScriptorToken);
                loginData.append('g', 't');
            }
            /* Making API call to authenticate a user */
            fetch(DataProvider.baseUrl + '/data/login', {
                method: 'POST',
                body: loginData
            })
                .then(response => {
                    response.json().then((response) => {
                        //debugger

                        /* Checking if error object was returned from the server */
                        if (response.Error) {
                            if (errorCallBack) {
                                errorCallBack();
                            }
                            else if (this.$router.history.current.path != '/login') {
                                this.redirectTo = this.$router.mode == 'hash' ? document.location.hash : document.location.pathname;
                                this.$router.push('/login');
                            }
                            return;
                        }

                        if (response.User && response.User.Name && response.User.ScriptorToken) {

                            this.$store.commit('SET_USER', response.User.Name)
                            this.$store.commit('SET_TOKEN', response.User.ScriptorToken)
                            this.$store.commit('SET_USERINFO', response.User)

                            if (this.remember && window.localStorage) {
                                window.localStorage.setItem('user', response.User.Name)
                                window.localStorage.setItem('token', response.User.ScriptorToken)
                            } else if (window.sessionStorage) {
                                window.sessionStorage.setItem('user', response.User.Name)
                                window.sessionStorage.setItem('token', response.User.ScriptorToken)
                            }

                            if (successCallback)
                                successCallback(response.User)
                        }
                    })
                })
        }

    }
};